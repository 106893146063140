import { Card, CardHeader, Divider } from "@mui/material";
import { grey } from "@mui/material/colors";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import TransactionService from "../services/transaction";
import { Transaction, TransactionMaster } from "../utils/types";
import { AxiosResponse } from "axios";
import Toast from "../ui-components/Toaster";
import BillToDetails from "../ui-components/Transaction-Details/Bill-To-Detail";
import PaymentDetails from "../ui-components/Transaction-Details/Payment-Detail";
import GeneralDetails from "../ui-components/Transaction-Details/General-Details";

export default function TransactionDetail() {
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState<TransactionMaster | undefined>();
  const [toast, setToast] = useState({ open: 1, message: "", type: "success" });
  const { id } = useParams(); // Get the transaction ID from the URL
  const [searchParams] = useSearchParams(); // Hook to access query parameters

  const transTypeParam = searchParams.get("type"); // Get transactionType from query parameters

  const getOneTransaction = useCallback(async () => {
    try {
      // get capture/authorization transaction detail
      let res: AxiosResponse<Transaction> = await TransactionService.getOne(id); 
      setTransaction(res?.data?.data);
    } catch (error: any) {
      setToast({
        open: Date.now(),
        message: error?.message,
        type: "error",
      });
    }
  }, [id]);

  useEffect(() => {
    getOneTransaction();
  }, [getOneTransaction]);

  return (
    <Card>
      <CardHeader
        avatar={
          <div
            onClick={() => { navigate(-1) }}
            style={{ cursor: "pointer" }}
          >
            <ArrowBackSharpIcon sx={{ color: grey[300] }} />
          </div>
        }
        sx={{
          borderBottom: 1,
          borderBottomColor: grey[300],
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "19px",
          color: "#54585A",
        }}
        title="Transaction Detail"
      />

      {transaction?.paymentTransactionDetails && (
        <GeneralDetails
          paymentTransactionDetails={transaction?.paymentTransactionDetails}
        />
      )}

      {(transaction?.paymentTransactionDetails?.account ||
        transaction?.customerDetails?.card ||
        transaction?.paymentTransactionDetails?.paymentInstrumentId) && (
        <>
          <Divider />
          <PaymentDetails
            account={transaction?.paymentTransactionDetails?.account}
            card={transaction?.customerDetails?.card}
            paymentInstrumentId={
              transaction?.paymentTransactionDetails?.paymentInstrumentId
            }
            customerTokenId = {transaction?.paymentTransactionDetails?.customerTokenId}
          />
        </>
      )}

      {transaction?.customerDetails?.billTo && (
        <>
          <Divider />
          <BillToDetails billTo={transaction?.customerDetails?.billTo} />
        </>
      )}
      <Toast open={toast.open} message={toast.message} type={toast.type} />
    </Card>
  );
}
