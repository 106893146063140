import React, { useState, useEffect } from "react";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import Dropdown from "react-bootstrap/Dropdown";
import { Accordion, Image } from "react-bootstrap";
import { useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { accessValidate, currentUserRole } from "./Access-Validate";
import Toast from "./Toaster";
import { Link } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import SVGConfigurations from "./icon-svg/configurations";
// import { SVGCustomers } from "./icon-svg/customers";
import { SVGTransactions } from "./icon-svg/transactions";
import { SVGDashboard } from "./icon-svg/dashboard";
// import { SVGUsers } from "./icon-svg/users";
import { SVGMyaccount } from "./icon-svg/myaccoun";
import { ErrorComponent } from "./Error-Component";
import { Loading } from "./Loading";
import { Breadcrumbs, Typography } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import KeyIcon from "@mui/icons-material/Key";
import { USER_ROLES } from "../utils/constants";

type Props = {
  children?: React.ReactNode;
};

export const PageLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const { instance } = useMsal();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [activeLabel, setActiveLabel] = useState<Array<string>>([]);
  const [user, setUser] = useState<any>();
  const [toast] = useState({ open: 1, message: "", type: "success" });
  // const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const setUserData = () => {
    const userdata = localStorage.getItem("puser") || "";
    if (userdata) {
      const userdetails = JSON.parse(userdata);
      setUser(userdetails);
    }
  };

  const drawBreadcrumbs = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        {activeLabel.map((item, index) => {
          return index !== activeLabel.length - 1 ? (
            <Typography key={index} color="text.primary">
              {item}
            </Typography>
          ) : (
            <Typography key={index} color="text.primary">
              {item}
            </Typography>
          );
        })}
        ;
      </Breadcrumbs>
    );
  };

  useEffect(() => {
    const page = location.pathname
      .replace("/", "")
      .replace(/_/g, " ")
      .split("/");
    // labelPage = page[0];
    // if (page.length > 1 && parseInt(page[1]) > 0) {
    //   labelPage = labelPage + " / edit";
    // } else if (page.length > 1) {
    //   labelPage = labelPage + " / " + page[1];
    // }
    setActiveLabel(page);
  }, [location]);

  useEffect(() => {
    window.addEventListener("storage", () => {
      setUserData();
    });
    setUserData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("puser");
    localStorage.removeItem("paccess");
    instance.logoutRedirect();
  };

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        <div
          className={isActive ? "main-wrapper overflow-hidden" : "main-wrapper"}
        >
          <div className="bg-white d-flex align-items-center px-3 py-2">
            <div className="d-flex align-items-center d-lg-none me-3">
              <div className="sidebar-menu-light me-3" onClick={handleToggle}>
                <div className="sidebar-menu-toggle-icon icon-xs cursor-pointer"></div>
              </div>
              <div className="mobile-header">
                <Link to="/">
                  <Image
                    src="/logo.png"
                    alt="Visual Comfort"
                    width={263}
                    height={22}
                    className="img-fluid"
                  />
                </Link>
                <h6>Payment Management</h6>
              </div>
            </div>
            <h1 className="d-none hide-crumb d-sm-inline-block h6 m-0 fw-bold my-3 mx-1 text-capitalize">
              {drawBreadcrumbs()}
            </h1>
            <div className="ms-auto px-1 d-flex align-items-center">
              <div className="user-details">
                <h5 className="lh-1 mb-1">{user?.displayName}</h5>
                <p className="lh-1 mb-1">{user?.mail}</p>
              </div>
              <div className="ms-2">
                <Dropdown align="end">
                  <Dropdown.Toggle
                    className="border-0 pe-0 ps-2"
                    variant="default"
                    id="dropdown-basic"
                  >
                    <SVGMyaccount size={34} box={34} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="ml-1 mr-1">
                    <Dropdown.Header className="user-details-mobile">
                      <h5 className="lh-1 mb-1">{user?.displayName}</h5>
                      <p className="lh-1 mb-1">{user?.mail}</p>
                    </Dropdown.Header>
                    <Dropdown.Divider className="user-details-mobile" />
                    <Dropdown.Item onClick={handleLogout}>
                      Log Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <SidebarMenu
            expand="lg"
            className={isActive ? "sidebar-menu-show" : ""}
          >
            <SidebarMenu.Collapse>
              <SidebarMenu.Header className="d-flex justify-content-start">
                <SidebarMenu.Brand>
                  <Link to="/">
                    <Image
                      src="/logo.png"
                      alt="Visual Comfort"
                      width={182}
                      height={32}
                      className="img-fluid  cursor-pointer"
                    />
                  </Link>
                  <h6>Payment Management</h6>
                </SidebarMenu.Brand>
                <SidebarMenu.Toggle />
                <div
                  className="d-lg-none icon-close-filled ms-auto cursor-pointer mt-1 opacity-50"
                  onClick={handleToggle}
                ></div>
              </SidebarMenu.Header>
              <SidebarMenu.Body>
                <SidebarMenu.Nav className="border-0">
                  <NavLink
                    to="/"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    <SidebarMenu.Nav.Icon>
                      <SVGDashboard />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Dashboard</SidebarMenu.Nav.Title>
                  </NavLink>
                  {/* {accessValidate("users") && (
                    <NavLink
                      to="/users"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <SidebarMenu.Nav.Icon>
                        <SVGUsers />
                      </SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>Users</SidebarMenu.Nav.Title>
                    </NavLink>
                  )} */}
                  {accessValidate("transactions") && (
                    <Accordion flush>
                      <Accordion.Item className="m-0" eventKey="2">
                        <Accordion.Header>
                          <SidebarMenu.Nav.Icon>
                            <SVGTransactions />
                          </SidebarMenu.Nav.Icon>
                          <SidebarMenu.Nav.Title>
                            Transactions
                          </SidebarMenu.Nav.Title>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                          <SidebarMenu.Nav>
                            <NavLink
                              to="/transactions/capture"
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <SidebarMenu.Nav.Icon>
                                <CreditCardIcon sx={{ fontSize: 20 }} />
                              </SidebarMenu.Nav.Icon>
                              <SidebarMenu.Nav.Title>
                                Capture Transactions
                              </SidebarMenu.Nav.Title>
                            </NavLink>
                            <NavLink
                              to="/transactions/refund"
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <SidebarMenu.Nav.Icon>
                                <CreditCardOffIcon sx={{ fontSize: 20 }} />
                              </SidebarMenu.Nav.Icon>
                              <SidebarMenu.Nav.Title>
                                Refund Transactions
                              </SidebarMenu.Nav.Title>
                            </NavLink>
                            <NavLink
                              to="/transactions/tokenization"
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                            >
                              <SidebarMenu.Nav.Icon>
                                <CreditCardIcon sx={{ fontSize: 20 }} />
                              </SidebarMenu.Nav.Icon>
                              <SidebarMenu.Nav.Title>
                                Tokenization Transactions
                              </SidebarMenu.Nav.Title>
                            </NavLink>
                          </SidebarMenu.Nav>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}
                  {/* {accessValidate("customers") && (
                    <NavLink
                      to="/customers"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <SidebarMenu.Nav.Icon>
                        <SVGCustomers />
                      </SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>Customers</SidebarMenu.Nav.Title>
                    </NavLink>
                  )} */}
                  {/* {accessValidate("configurations") && (
                    <NavLink
                      to="/configurations"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <SidebarMenu.Nav.Icon>
                        <SVGConfiugarions />
                      </SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>
                        Configurations
                      </SidebarMenu.Nav.Title>
                    </NavLink>
                  )} */}

{accessValidate("customers") && (
                    <Accordion flush>
                      <Accordion.Item className="m-0" eventKey="3">
                        <Accordion.Header>
                          <SidebarMenu.Nav.Icon>
                            <CreditCardIcon />
                          </SidebarMenu.Nav.Icon>
                          <SidebarMenu.Nav.Title>
                            Customers
                          </SidebarMenu.Nav.Title>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                          <SidebarMenu.Nav>
                            <NavLink to="/customers" className={({ isActive }) => isActive ? "active" : "" }>
                              <SidebarMenu.Nav.Icon>
                                <CreditCardIcon sx={{ fontSize: 20 }} />
                              </SidebarMenu.Nav.Icon>
                              <SidebarMenu.Nav.Title>
                                Customer List
                              </SidebarMenu.Nav.Title>
                            </NavLink>
                          </SidebarMenu.Nav>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}
                  {(accessValidate("configurations") &&
                    currentUserRole().includes(USER_ROLES.ADMIN)) && (
                      <Accordion flush>
                        <Accordion.Item className="m-0" eventKey="2">
                          <Accordion.Header>
                            <SidebarMenu.Nav.Icon>
                              <SVGConfigurations />
                            </SidebarMenu.Nav.Icon>
                            <SidebarMenu.Nav.Title>
                              Configurations
                            </SidebarMenu.Nav.Title>
                          </Accordion.Header>
                          <Accordion.Body className="p-0">
                            <SidebarMenu.Nav>
                              <NavLink
                                to="/configurations/security-keys"
                                className={({ isActive }) =>
                                  isActive ? "active" : ""
                                }
                              >
                                <SidebarMenu.Nav.Icon>
                                  <KeyIcon sx={{ fontSize: 20 }} />
                                </SidebarMenu.Nav.Icon>
                                <SidebarMenu.Nav.Title>
                                  Security Keys
                                </SidebarMenu.Nav.Title>
                              </NavLink>
                            </SidebarMenu.Nav>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    )}
                </SidebarMenu.Nav>
              </SidebarMenu.Body>
            </SidebarMenu.Collapse>
          </SidebarMenu>
          <main className="bg-lightest main-container container-fluid">
            <h1 className="show-crumb d-sm-inline-block h6 m-0 fw-bold my-3 mx-1 text-capitalize">
              {drawBreadcrumbs()}
            </h1>
            {children}
          </main>
        </div>
      </MsalAuthenticationTemplate>
      <Toast open={toast.open} message={toast.message} type={toast.type} />
    </>
  );
};
