import { useEffect, useState, useCallback } from "react";
import { Card, CardContent, debounce, Tooltip } from "@mui/material";
import Datatable from "../ui-components/Custom-Data-Table";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowHeightParams,
  GridSortModel,
} from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import TransactionService from "../services/transaction";
import Toast from "../ui-components/Toaster";
import { Link } from "react-router-dom";
import {
  RefundTransactions,
  Search,
  TransactionsResponse,
} from "../utils/types";
import { AxiosResponse } from "axios";
import { toPascalCase } from "../utils/helpers";
import { currentUserRole } from "../ui-components/Access-Validate";
import { USER_ROLES } from "../utils/constants";
export default function Transactions() {
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(100);
  let refundedFilter = localStorage.getItem("refundedFilter");
  let sRefundedFilter = refundedFilter ? JSON.parse(refundedFilter) as Search : null;
  const [filter, setFilter] = useState<Search>(
    sRefundedFilter || {
      searchTerm: null,
      fromDate: null,
      toDate: null,
      merchant: null,
      status: "",
      currency: "",
      applicationName: "ALL",
      customerType: null
    }
  );
  const [sortOrder, setSortOrder] = useState<any>("DESC");
  const [fieldName, setFieldName] = useState<string>("id");
  const [rowsData, setRowsData] = useState<RefundTransactions[]>([]);
  const [toast, setToast] = useState({ open: 1, message: "", type: "success" });
  const [size, setPageSize] = useState(10);
  const [userFilter, setUserFilter] = useState<string[]>([]);
  const [refundStatuses, setRefundStatuses] = useState<string[]>([]);

  const getRowHeight = ({ model }: GridRowHeightParams) => {
    return model.customerInfo ? 80 : 46;
  };
  const columns: GridColDef[] = [
    {
      field: "transactionId",
      headerName: "Transaction ID",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link to={"/transactions/refunded-detail/" + params.row.id}>
          {params.row.transactionId}
        </Link>
      ),
    },
    { field: "refundDate", headerName: "Date & Time", width: 140, sortable: true },
    { field: "amount", headerName: "Amount" },
    { field: "referenceCode", headerName: "Reference" },
    { field: "userId", headerName: "User", sortable: false },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title={params.row.errorDetail || ""} placement="top">
            <span>{toPascalCase(params?.value)}</span>
          </Tooltip>
        );
      },
      cellClassName: (params: GridCellParams) => {
        return params?.value?.toLowerCase();
      },
    },
    { field: "merchant", headerName: "Merchant", width: 100, sortable: false },
    {
      field: "customerInfo",
      headerName: "Customer",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.customerInfo && (
            <>
              <span style={{ fontSize: '12px' }}>
                {`${params.row.customerInfo?.firstName} ${params.row.customerInfo?.lastName}`}
                <br />
                {`${params.row.customerInfo?.email}`}
                <br />
                {params.row.customerInfo?.phoneNumber &&
                  `${params.row.customerInfo?.phoneNumber?.slice(0,2)}XXXXX${params.row.customerInfo?.phoneNumber?.slice(-2)}`}
              </span> 
            </>
          )}
        </>
      ),
    },
    {
      headerName: "Action",
      field: "actions",
      type: "actions",
      width: 65,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Link to={"/transactions/refunded-detail/" + params.row.id}>
          <InfoIcon />
        </Link>
      ),
    },
  ];

  const handleSortModelChange = (sortModel: GridSortModel) => {
    setFieldName(sortModel[0]?.field);
    setSortOrder(sortModel[0]?.sort);
  };

  const getRefundedUsers = async () => {
    TransactionService.getRefundedUsers()
      .then((result) => {
        const userIds = result?.data?.data;
        setUserFilter(userIds);
      })
      .catch((error: any) => {
        setToast({
          open: Date.now(),
          message: error?.message,
          type: "error",
        });
      });
  };
const getRefundStatuses = async () => {
    TransactionService.getRefundedStatuses()
      .then((result) => {
        const statuses = result?.data?.data;
        setRefundStatuses(statuses);
      })
      .catch((error: any) => {
        setToast({
          open: Date.now(),
          message: error?.message,
          type: "error",
        });
      });
  };
  const getAllData = useCallback(
    async (
      size: number,
      page: number,
      fieldName: string,
      sortOrder: string,
      search: Search
    ) => {
      try {
        const res: AxiosResponse<TransactionsResponse> =
          await TransactionService.getAllRefundedTransaction(
            size,
            page + 1,
            fieldName,
            sortOrder,
            search
          );
        if (res && res.status === 200) {
          setRowsData(res?.data?.data?.records);
          setRowCount(res?.data?.data?.paginationInfo?.totalRecords);
        } else if (res) {
          setToast({
            open: Date.now(),
            message: res?.data.message,
            type: "error",
          });
        }
      } catch (error: any) {
        setToast({
          open: Date.now(),
          message: error?.message,
          type: "error",
        });
      }
    },
    []
  );

  // Debounce the function with a delay of 100ms
  const debouncedGetAllData = useCallback(
    debounce((pageSize, page, fieldName, sortOrder, search) => {
      getAllData(pageSize, page, fieldName, sortOrder, search);
    }, 100), 
    [getAllData]
  );
  
  useEffect(() => {
    localStorage.setItem("refundedFilter", JSON.stringify(filter));
    debouncedGetAllData(size, page, fieldName, sortOrder, filter);
  
    return () => {
      debouncedGetAllData.clear(); // Cleanup debounce on unmount or dependency change
    };
  }, [size, page, fieldName, sortOrder, filter, debouncedGetAllData]);

  useEffect(() => {
    getRefundedUsers();
    getRefundStatuses();
  }, []);

  return (
    <>
      <Card>
        <CardContent className="table-cardcontent">
          <Datatable
            rows={rowsData || []}
            userFilter={userFilter}
            statuses={refundStatuses}
            getRowHeight={getRowHeight}
            page={page ? page : 0}
            columns={columns}
            rowCount={rowCount || 0}
            pageSize={size}
            setPageSize={setPageSize}
            onPageChange={(newPage: number) => setPage(newPage)}
            onSortModelChange={handleSortModelChange}
            setPage={setPage}
            searchText={filter}
            setSearchText={setFilter}
            showtoolbar={true}
            showstatus={true}
            showapplication={true}
            datepicker={true}
            showMerchant={true}
            showUser={currentUserRole().includes(USER_ROLES.ADMIN)}
            transactionType="refunded"
            showExport={true}
            showCustomerType={false}
          />
        </CardContent>
      </Card>
      <Toast open={toast.open} message={toast.message} type={toast.type} />
    </>
  );
}
