import { useCallback, useEffect, useState } from 'react';
import transactionService from "../services/transaction";
import { AxiosResponse } from "axios";
import { ApiResponse, CustomerDetails, CustomerListData } from '../utils/types';
import { VisibilitySharp as VisibilityIcon } from "@mui/icons-material";
import { Link, useLocation } from 'react-router-dom';
import { Card, CardContent, debounce } from '@mui/material';
import Datatable from "../ui-components/Custom-Data-Table";
import Toast from "../ui-components/Toaster";
import {
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { sassTrue } from 'sass';
import { Search } from "../utils/types";


export default function Customers() {
  const location = useLocation();
  const [toast, setToast] = useState({ open: 1, message: "", type: "success" });
  const [page, setPage] = useState(0);
  const [size, setPageSize] = useState(10);
  const [fieldName, setFieldName] = useState<string>("Id");
  const [sortOrder, setSortOrder] = useState<any>("DESC");
  const [rowsData, setRowsData] = useState<CustomerDetails[]>([]);
  const [rowCount, setRowCount] = useState<number>(50);
  const [filter, setFilter] = useState<Search>({
      searchTerm: null,
      fromDate: null,
      toDate: null,
      status: "",
      currency: null,
      applicationName: null,
      customerType: location?.state?.customerType ?? "WHOLESALE"
  });

  useEffect(() => {
    if (location.state?.customerType) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        customerType: location.state.customerType,
      }));
    }
  }, [location.state]);

  const columns: GridColDef[] = [
    { field: "sapUserId", headerName: "SAP ID", sortable: true, flex: 1 },
    { field: "jdeUserId", headerName: "JDE ID", sortable: true, flex: 1 },
    { field: "magentoUserId", headerName: "Magento ID", sortable: true, flex: 1 },
    { field: "d365UserId", headerName: "D365 ID", sortable: true, flex: 1 },
    { field: "firstName", headerName: "First Name", sortable: true, flex: 1 },
    { field: "lastName", headerName: "Last Name", sortable: true, flex: 1 },
    { field: "postalCode", headerName: "Postal Code", sortable: true, flex: 1 },
    { field: "email", headerName: "Email", sortable: true, flex: 1 },
    { field: "phoneNumber", headerName: "Phone Number", sortable: true, flex: 1 },
    {
      headerName: "Details",
      field: "actions",
      type: "actions",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          to= "/customers/customer-detail"
          state= {{ 
            sapUserId: params.row.sapUserId, 
            jdeUserId: params.row.jdeUserId, 
            magentoUserId: params.row.magentoUserId, 
            d365UserId: params.row.d365UserId,
            customerType: filter.customerType // Pass filter state 
          }}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <VisibilityIcon />
        </Link>
      ),
    },
  ];

  // Conditionally filter columns if customerType is 'WHOLESALE'
  const filteredColumns = filter.customerType === "WHOLESALE"
                          ? columns.filter((col) => col.field !== "d365UserId") // hide 'd365UserId'
                          : columns.filter((col) => col.field !== "sapUserId" && col.field !== "jdeUserId" && col.field !== "magentoUserId");

  const handleSortModelChange = (sortModel: GridSortModel) => {
    setFieldName(sortModel[0]?.field);
    setSortOrder(sortModel[0]?.sort);
  };

  const getAllData = useCallback(
    async (size: number, page: number, fieldName: string, sortOrder: string, search: Search) => {
      try {
        const res: AxiosResponse<ApiResponse<CustomerListData>> = await transactionService.getAllCustomers(
          size,
          page + 1,
          fieldName,
          sortOrder,
          search
        );
        if (res && res.status === 200 && res.data.isSuccess) {
          setRowsData(res?.data?.data?.records);
          setRowCount(res?.data?.data?.paginationInfo?.totalRecords);
        } else if (res) {
          setToast({
            open: Date.now(),
            message: res?.data.message,
            type: "error",
          });
        }
      } catch (error: any) {
        setToast({
          open: Date.now(),
          message: error?.message,
          type: "error",
        });
      }
    }, []);

  // Debounce the function with a delay of 100ms
  const debouncedGetAllData = useCallback(
    debounce((pageSize, page, fieldName, sortOrder, search) => {
      getAllData(pageSize, page, fieldName, sortOrder, search);
    }, 100), 
    [getAllData]
  );
  
  useEffect(() => {
    debouncedGetAllData(size, page, fieldName, sortOrder, filter);
  
    return () => {
      debouncedGetAllData.clear(); // Cleanup debounce on unmount or dependency change
    };
  }, [size, page, fieldName, sortOrder, filter, debouncedGetAllData ]);

  return (
    <>
      <Card>
        <CardContent className="table-cardcontent">
          <Datatable
            columns={filteredColumns}
            rows={rowsData || []}
            rowCount={rowCount || 0}
            setPageSize={setPageSize}
            pageSize={size}
            setPage={setPage}
            page={page || 0}
            searchText={filter}
            setSearchText={setFilter}
            showtoolbar={true}
            onPageChange={(newPage: number) => setPage(newPage)}
            onSortModelChange={handleSortModelChange}
            isSearchOnly={true}
            showCustomerType={true}
          />
        </CardContent>
      </Card>

      <Toast open={toast.open} message={toast.message} type={toast.type} />
    </>
  );
}