import axios from "axios";
import { Search } from "../utils/types";

class TransactionService {
  getAllTransaction = (
    PageSize: number,
    PageNumber: number,
    SortColumn: string,
    SortDirection: string,
    search: Search
  ) => {
    return axios.get("PaymentReports/payment-transactions/filter", {
      params: { PageSize, PageNumber, SortColumn, SortDirection, ...search },
    });
  };

  getAllRefundedTransaction = (
    PageSize: number,
    PageNumber: number,
    SortColumn: string,
    SortDirection: string,
    search: Search
  ) => {
    return axios.get("PaymentReports/refundpayment-transactions/filter", {
      params: { PageSize, PageNumber, SortColumn, SortDirection, ...search },
    });
  };

  getTransactionOverview = () => {
    return axios.get("PaymentReports/transactions-overview");
  };

  getTransactionSummary = () => {
    return axios.get("PaymentReports/transactions-summary");
  };

  getOne = (id: string | undefined) => {
    return axios.get(`PaymentReports/payment-transaction/${id}`);
  };

  getRefundedOne = (id: string | undefined) => {
    return axios.get(`PaymentReports/refund-transaction/${id}`);
  };

  getCapturedUsers = () => {
    return axios.get(`PaymentReports/unique-users-capture`);
  };

  getRefundedUsers = () => {
    return axios.get(`PaymentReports/unique-users-refund`);
  };

  getCapturedStatuses = () => {
    return axios.get(`PaymentReports/unique-statuses-capture`);
  };

  getRefundedStatuses = () => {
    return axios.get(`PaymentReports/unique-statuses-refund`);
  };

  getAllCustomers = ( PageSize: number, PageNumber: number, SortColumn: string, SortDirection: string, search: Search ) => {
    return axios.get("PaymentReports/customers", {
      params: { PageSize, PageNumber, SortColumn, SortDirection, ...search },
    });
  };

  getAllTokenizedTransaction = ( PageSize: number, PageNumber: number, SortColumn: string, SortDirection: string, search: Search ) => {
    return axios.get("PaymentReports/transactions-tokenized", {
      params: { PageSize, PageNumber, SortColumn, SortDirection, ...search },
    });
  };

  getTransactionOverviewByMonth = ( month?: number ) => {
    return axios.get("PaymentReports/transactions-overview-by-month", {
      params: { month },
    });
  };
}

const transactionService = new TransactionService();
export default transactionService;
