import axios from "axios";
import { EditCustomerCardInput , SessionRequestInput } from "../utils/types";

class FiservService {
  
  getFiservHppResponseDetails = (internalRequestId: string, transactionId: number) => {
    return axios.get(`api/FiservPayment/fiserv-hpp-response-details/${internalRequestId}/${transactionId}`);
  }

  getSavedCustomerDetails = (sapUserId: string, jdeUserId: string, magentoUserId: string, d365UserId: string) => {
    return axios.get(`api/FiservPayment/customer-details`, {
      params: { sapUserId, jdeUserId, magentoUserId, d365UserId }
    });
  }
  getCustomerBilltoDetails = (customerId: number) => {
    return axios.get(`api/FiservPayment/customer-billto`, {
      params: { customerId}
    });
  }
  getSavedCardsDetails = (sapUserId: string, jdeUserId: string, magentoUserId: string, d365UserId: string, isIncludeDeleted: boolean, isIncludeOneTimeCard: boolean, applicationName: string) => {
    return axios.get(`api/FiservPayment/saved-cards`, {
      params: { sapUserId, jdeUserId, magentoUserId, d365UserId, isIncludeDeleted, isIncludeOneTimeCard },
      headers: { 'x-application-name': applicationName }
    });
  }
  getCustomerERPIds = () => {
    return axios.get(`api/FiservPayment/customer-erp-ids`, {
    });
  }

  deleteCard = (token: string, sapUserId: string, jdeUserId: string, magentoUserId: string, d365UserId: string) => {
    return axios.delete(`api/FiservPayment/delete-card`, {
      params: { token, sapUserId, jdeUserId, magentoUserId, d365UserId, isRequestedFromCardMgmtUI: true}
    });
  }
  
  updateCard = (editCustomerCardInput: EditCustomerCardInput) => {
    return axios.post(`api/FiservPayment/update-card`, editCustomerCardInput);
  }

  sessionToAddCard = (sessionRequestInput: SessionRequestInput) => {
    return axios.post(
      'api/FiservPayment/generate-tokenization-session',
      sessionRequestInput
    );
  };

}

const fiservService = new FiservService();
export default fiservService;
