import { Box, Button, Container, Grid, Typography, Card, CardHeader, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "../App";
import fiservService from "../services/fiserv";
import { AxiosResponse } from "axios";
import { HppTransactionDetailResponse } from "../utils/types";
import "../styles/_savedCards.css";

export default function FiservHppResponse() {
  // const [hppData, setHppData] = useState<HppTransactionDetailResponse>({});
  const [hppData, setHppData] = useState<HppTransactionDetailResponse>();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const query = useQuery();

  const internalRequestId = query.get('internalRequestId') as string;
  const transactionId = query.get('transactionId') as unknown as number;

  const loadApiData = async () => {
    try {
      const res: AxiosResponse<HppTransactionDetailResponse> = await fiservService.getFiservHppResponseDetails(
        internalRequestId,
        transactionId
      );
      if (res?.status === 200) {
        setHppData(res?.data);
      }

    } catch (error: any) {
      console.log(error);
    }
  }
  useEffect(() => {
    loadApiData()
  }, [transactionId, internalRequestId]);

  const handleClose = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const goToCustomerCardsPage = () => {
    navigate('/customers/CustomerCards');
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100%",
        backgroundColor: "#fff",
      }}
    >
      <Container maxWidth="lg" className="filenotfound">
        <Card>
          <CardHeader
            title={
              <Grid container justifyContent="center">
                <img
                  src="https://www.visualcomfort.com/media/logo/stores/1/vcc_logo_horz_gray_web_1.png"
                  alt="Logo"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </Grid>
            }
          />
          <CardContent>
            <Typography variant="h4" align="center">
              Thank You!
            </Typography>
            <Typography variant="h6" align="center">
              Hosted Payment Page Response
            </Typography>
            {hppData && (
              <>
            {hppData.isSuccess ? (
              <>
                <CardContent>

                  <Grid item xs={4} md={2}>
                    <span className="fw-bold"> Request Id : </span>
                  </Grid>
                  <Grid item >
                    {internalRequestId}
                  </Grid>

                </CardContent>
                <CardContent>
                  <Grid item xs={4} md={2}>
                    <span className="fw-bold"> ERP ID : </span>
                  </Grid>
                  <Grid item >
                    SAP ID ({hppData?.data?.sapUserId || "Not Available"}), JDE ID ({hppData?.data?.jdeUserId || "Not Available"}), Magento ID ({hppData?.data?.magentoUserId || "Not Available"})
                  </Grid>
                  <Typography variant="h6">Card Information</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4} md={2}>
                      <span className="fw-bold">Card Token:</span>
                    </Grid>
                    <Grid item xs={8} md={4}>
                      {hppData.data.paymentMethod.tokenId}
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <span className="fw-bold">Type:</span>
                    </Grid>
                    <Grid item xs={8} md={4}>
                      {hppData.data.paymentMethod.cardType}
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <span className="fw-bold">First Name:</span>
                    </Grid>
                    <Grid item xs={8} md={4}>
                      {hppData.data.paymentMethod.firstName}
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <span className="fw-bold">Last Name:</span>
                    </Grid>
                    <Grid item xs={8} md={4}>
                      {hppData.data.paymentMethod.lastName}
                    </Grid>

                    <Grid item xs={4} md={2}>
                      <span className="fw-bold">Last 4:</span>
                    </Grid>
                    <Grid item xs={8} md={4}>
                      {hppData.data.paymentMethod.last4}
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <span className="fw-bold">Expiration:</span>
                    </Grid>
                    <Grid item xs={8} md={4}>
                      {hppData.data.paymentMethod.expirationMonth} / {hppData.data.paymentMethod.expirationYear}
                    </Grid>
                  </Grid>
                </CardContent>

                <CardContent>
                  <Typography variant="h6">Transaction Details</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4} md={2}>
                      <span className="fw-bold">PgTransactionId: </span>
                    </Grid>
                    <Grid item xs={8} md={4}>
                      {hppData.data?.transaction?.pgTransactionId}
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <span className="fw-bold">Transaction Amount: </span>
                    </Grid>
                    <Grid item xs={8} md={4}>
                      {hppData.data?.transaction?.transactionAmount} {hppData.data?.transaction?.currency}
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <span className="fw-bold">Return Code:</span>
                    </Grid>
                    <Grid item xs={8} md={4}>
                      {hppData.data?.transaction?.returnCode}
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <span className="fw-bold">Transaction Status:</span>
                    </Grid>
                    <Grid item xs={8} md={4}>
                      {hppData.data?.transaction?.transactionStatus}
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <span className="fw-bold">Authorization Code:</span>
                    </Grid>
                    <Grid item xs={8} md={4}>
                      {hppData.data?.transaction?.authorizationCode}
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <span className="fw-bold">Verification:</span>
                    </Grid>
                    <Grid item xs={8} md={4}>
                      ScVerification={hppData.data?.transaction?.scVerification}, AddressVerification={hppData.data?.transaction?.addressVerification}
                    </Grid>
                  </Grid>
                </CardContent>
              </>
            ) : (
              <CardContent>
                <Grid item xs={8} md={4}>
                  <span className="fw-bold">Failed to process the request:</span>
                </Grid>
                <Grid item>
                  {hppData?.message}
                </Grid>
              </CardContent>
            )}
            <Link
              to= "/customers/customer-detail"
              state= {{ sapUserId: hppData?.data?.sapUserId, jdeUserId: hppData?.data?.jdeUserId, magentoUserId: hppData?.data?.magentoUserId }}
              className="custom-button-link"
            >
                Go to Customer Cards
            </Link>
            {/* <Button onClick={handleClose} variant="contained">
              Close this Window
            </Button> */}
            
            </>
          )}
            
          </CardContent>
        </Card>
      </Container>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Close Window</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please close this window manually.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
